<template>
  <div class="activityintroduce">
    <div class="activityintroduce_center">
      <nav>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/activity' }">通知公告</el-breadcrumb-item>
          <el-breadcrumb-item>{{introData.title}}</el-breadcrumb-item>
        </el-breadcrumb>
      </nav>
      <header>
        <div class="h_left">
          <div v-if="introData.imgurl">
            <img :src="introData.imgurl" alt />
            <div class="serieslabel">
              <p>{{introData.labelName}}</p>
            </div>
          </div>
          <div v-else>
            <img src="../../../assets/components/bigdefault.png" alt />
            <div class="serieslabel">
              <p>{{introData.labelName}}</p>
            </div>
          </div>
          <div class="h_icon">
            <p class="h_p_like">
              <i class="like" v-if="!praiseFlag" @click="messagePraise"></i>
              <i class="likes" v-else @click="deletePraise"></i>
              <span>{{introData.praiseCount}}</span>
            </p>
            <p class="h_p_star">
              <i class="star" v-if="!collectionFlag" @click="messageCollection"></i>
              <i class="stars" v-else @click="messageDeleteCollection"></i>
              <span>{{introData.collectionCount}}</span>
            </p>
          </div>
        </div>
        <div class="h_right">
          <p class="r_title">{{introData.title}}</p>
          <p class="r_user">公告介绍：{{introData.info}}</p>
          <p class="r_subject">发起者：{{introData.createName}}</p>
          <p class="r_time">起止时间：{{introData.starttime}}至{{introData.endtime}}</p>
          <p class="r_grade">学科：{{introData.gradeName}}</p>
          <p class="r_period">学段：{{introData.periodName}}</p>
          <p class="r_grade">年级：{{introData.subjectName}}</p>
          <el-button type="primary" @click="joinactive(null,null,null)" size="mini">参与公告</el-button>
        </div>
      </header>
      <main>
        <writings v-for="item,index in activelist" :key="item.id" :listdata="item" :nowindex="index" :listid="outid"></writings>
      </main>
    </div>
  </div>
</template>
<script>
import writings from "../../../components/writings.vue";
export default {
  components: { writings },
  data() {
    return {
      // 面包屑
      breadtitle: null,
      // dangqinaid
      outid:null,
      // 详情
      introData: null,
      // 公告
      activelist: null,
      // 点赞
      collectionFlag: null, //收藏
      praiseFlag: null, //点赞

    };
  },
  created() {
    console.log(this.$route.query.id,this.$route.query.id)
    this.outid=this.$route.query.id
    this.queryact(); //获取详情
    this.querygong(); // 获取公告
    // 点赞和收藏
    // this.queryMessageStatus(this.$route.query.id);
  },
  methods: {
    // 获取通知公告详情
    queryact() {
      console.log(this.$route.query.id, "获取通知公告详情");
      let aid = this.$route.query.id;
      let aa = {
        imgurl: null,
        id: 1,
        title: "一体化系统上线试运行通告",
        info: "朝阳区一体化教研系统上线试运行，请各位教研员，老师使用。多提宝贵意见",
        labelName: "系统上线/通知公告",
        label: "通知公告",
        periodName: ["小学", "初中"],
        gradeName: ["三年级", "九年级"],
        subjectName: ["综合"],
        member: "200",
        createName: "刘老师",
        starttime: "2022-09-01",
        endtime: "2022-12-01",
        praiseCount: "200",
        collectionCount: "100",
      };
      let ab = {
        imgurl: null,
        id: 1,
        title: "一体化系统上线试运行问题反馈收集",
        info: "朝阳区一体化教研系统上线试运行，请各位教研员，老师使用。如使用过程中遇到问题请形成文档后，在资料收集模块点击上传资料进行提交。",
        labelName: "一体化系统/问题收集/系统上线",
        label: "资料收集",
        periodName: ["小学", "初中"],
        gradeName: ["三年级", "九年级"],
        subjectName: ["综合"],
        member: "200",
        createName: "刘老师",
        starttime: "2022-09-01",
        endtime: "2022-12-01",
        praiseCount: "200",
        collectionCount: "100",
      };
      let ac = {
        imgurl: null,
        id: 1,
        title: "一体化系统上线试运行投票反馈",
        info: "一体化系统上线试运行，请您进行满意度投票",
        labelName: "系统上线/投票/一体化系统",
        label: "投票",
        periodName: ["小学", "初中"],
        gradeName: ["三年级", "九年级"],
        subjectName: ["综合"],
        member: "200",
        createName: "刘老师",
        starttime: "2022-09-01",
        endtime: "2022-12-01",
        praiseCount: "200",
        collectionCount: "100",
      };
      let ad = {
        imgurl: null,
        id: 1,
        title: "一体化系统上线试运行问卷调查",
        info: "一体化系统上线试运行，请您抽出几分钟时间进行一次小小的问卷调查",
        labelName: "系统上线/试运行/问卷调查",
        label: "问卷调查",
        periodName: ["小学", "初中"],
        gradeName: ["三年级", "九年级"],
        subjectName: ["综合"],
        member: "200",
        createName: "刘老师",
        starttime: "2022-09-01",
        endtime: "2022-12-01",
        praiseCount: "200",
        collectionCount: "100",
      };

      let data = null;
      if (aid == 1) {
        data = aa;
      } else if (aid == 2) {
        data = ab;
      } else if (aid == 3) {
        data = ac;
      } else if (aid == 4) {
        data = ad;
      } else {
        data = null;
        this.$message.error("数据错误");
        return;
      }
      let aData = JSON.parse(JSON.stringify(data));
      aData.periodName = aData.periodName.join("/");
      aData.gradeName = aData.gradeName.join("/");
      aData.subjectName = aData.subjectName.join("/");
      this.introData = aData;
    },
    // 获取公告
    querygong() {
      console.log(this.$route.query.id, "获取通知公告详情");
      let aid = this.$route.query.id;
      let aa = [
        {
          id: 1,
          type: "公告文档",
          content:
            "朝阳区一体化教研系统一期上线试运行。本期系统上线功能包括教研圈，课程上传，资料上传等功能。请各位老师及教研员使用，后续功能会在二期工作中陆续上线。（若有使用文档可提示下载使用文档）",
          ids: 1,
          title: "朝阳区一体化教研系统一期上线试运行",
        },
      ];
      let ab = [
        {
          id: 1,
          type: "资料收集",
          content: "一体化系统上线试运行问题反馈收集",
          ids: 1,
          title: "一体化系统上线试运行问题反馈收集",
        },
      ];
      let ac = [
        {
          id: 3,
          type: "投票",
          content: "一体化系统上线试运行满意度投票反馈",
          ids: 1,
          title: "一体化系统上线试运行满意度投票反馈",
        },
      ];
      let ad = [
        {
          id: 4,
          type: "调查问卷",
          content: "一体化系统上线试运行问卷调查",
          ids: 1,
          title: "一体化系统上线试运行问卷调查",
        },
      ];
      // let af = [
      //   {
      //     id: 1,
      //     type: "公告文档",
      //     content:
      //       "朝阳区一体化教研系统一期上线试运行。本期系统上线功能包括教研圈，课程上传，资料上传等功能。请各位老师及教研员使用，后续功能会在二期工作中陆续上线。（若有使用文档可提示下载使用文档）",
      //     ids: 1,
      //     title: "朝阳区一体化教研系统一期上线试运行",
      //   },
      //   {
      //     id: 2,
      //     type: "资料收集",
      //     content: "公告文档标题",
      //     ids: 1,
      //     title: "标题1",
      //   },
      //   {
      //     id: 3,
      //     type: "投票",
      //     content: "公告文档标题",
      //     ids: 1,
      //     title: "标题1",
      //   },
      //   {
      //     id: 4,
      //     type: "调查问卷",
      //     content: "公告文档标题",
      //     ids: 1,
      //     title: "标题1",
      //   },
      //   {
      //     id: 5,
      //     type: "公告文档",
      //     content: "公告文档标题",
      //     ids: 1,
      //     title: "标题1",
      //   },
      // ];
      let data = null;
      if (aid == 1) {
        data = aa;
      } else if (aid == 2) {
        data = ab;
      } else if (aid == 3) {
        data = ac;
      } else if (aid == 4) {
        data = ad;
      } else {
        data = null;
        this.$message.error("数据错误");
        return;
      }
      this.activelist = data;
    },

    //申请参加公告
    joinactive() {
      this.$message({
        message: "参与公告成功",
        type: "success",
      });
    },

    // 点赞
    async messagePraise() {
      return;
      let id = this.$route.query.id;
      const { data, status } = await this.$Http.messagePraise({
        serviceId: id,
        type: 1,
      });
      if (status == 200) {
        // console.log(data, status, "点赞");
        this.queryMessageStatus(id);
        this.queryCourselist(id);
      }
    },
    // 取消点赞
    async deletePraise() {
      return;
      let id = this.$route.query.id;
      const { data, status } = await this.$Http.deletePraise({
        serviceId: id,
        type: 1,
      });
      if (status == 200) {
        // console.log(data, status, "取消点赞");
        this.queryMessageStatus(id);
        this.queryCourselist(id);
      }
    },
    // 收藏
    async messageCollection() {
      return;
      let id = this.$route.query.id;
      const { data, status } = await this.$Http.messageCollection({
        serviceId: id,
        type: 1,
      });
      if (status == 200) {
        // console.log(data, status, "收藏");
        this.queryMessageStatus(id);
        this.queryCourselist(id);
      }
    },
    // 取消收藏
    async messageDeleteCollection() {
      return;
      let id = this.$route.query.id;
      const { data, status } = await this.$Http.messageDeleteCollection({
        serviceId: id,
        type: 1,
      });
      if (status == 200) {
        // console.log(data, status, "取消收藏");
        this.queryMessageStatus(id);
        this.queryCourselist(id);
      }
    },
    // 查询点赞和收藏
    async queryMessageStatus(id) {
      const { data, status } = await this.$Http.queryMessageStatus({
        serviceId: id,
        type: 1,
      });
      if (status == 200) {
        console.log(data, status, "点赞和收藏");
        this.collectionFlag = data.collectionFlag;
        this.praiseFlag = data.praiseFlag;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.activityintroduce {
  .activityintroduce_center {
    width: 1200px;
    margin: 0 auto;
    nav {
      padding: 15px 0;
    }
    header {
      // width: 1200px;
      // height: 365px;
      padding: 20px;
      background: #ffffff;
      display: flex;
      justify-content: space-between;
      .h_left {
        position: relative;
        .serieslabel {
          padding: 5px 10px;
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          background: #2b7dee;
          //   width: 66px;
          //   height: 25px;

          opacity: 1;
          p {
            // width: 48px;
            // height: 16px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            // line-height: 14px;
          }
        }
        img {
          width: 500px;
          height: 300px;
        }
        .h_icon {
          display: flex;
          justify-content: flex-start;
          .h_p_like {
            .like {
              cursor: pointer;
              display: inline-block;
              width: 30px;
              height: 27px;
              background: url("../../../assets/icon/blanklike.png") no-repeat;
            }
            .likes {
              cursor: pointer;
              display: inline-block;
              width: 30px;
              height: 27px;
              background: url("../../../assets/icon/solidlike.png") no-repeat;
            }
            span {
              display: inline-block;
              //   width: 29px;
              //   height: 21px;
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #141516;
              //   line-height: 19px;
              vertical-align: super;
            }
          }
          .h_p_star {
            margin-left: 40px;

            .star {
              cursor: pointer;
              display: inline-block;
              width: 30px;
              height: 27px;
              background: url("../../../assets/icon/blankstar.png") no-repeat;
            }
            .stars {
              cursor: pointer;
              display: inline-block;
              width: 30px;
              height: 27px;
              background: url("../../../assets/icon/solidestar.png") no-repeat;
            }
            span {
              display: inline-block;
              //   width: 29px;
              //   height: 21px;
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #141516;
              //   line-height: 19px;
              vertical-align: super;
            }
          }
          // .h_p_play {
          //   margin-left: 40px;
          //   i {
          //     display: inline-block;
          //     width: 32px;
          //     height: 27px;
          //     background: url("../../../assets/icon/playbig.png") no-repeat;
          //   }
          //   span {
          //     display: inline-block;
          //     //   width: 29px;
          //     //   height: 21px;
          //     font-size: 16px;
          //     font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          //     font-weight: 400;
          //     color: #141516;
          //     //   line-height: 19px;
          //     vertical-align: super;
          //   }
          // }
          // .h_p_comment {
          //   margin-left: 40px;
          //   i {
          //     display: inline-block;
          //     width: 32px;
          //     height: 27px;
          //     background: url("../../../assets/icon/commentbig.png") no-repeat;
          //   }
          //   span {
          //     display: inline-block;
          //     //   width: 29px;
          //     //   height: 21px;
          //     font-size: 16px;
          //     font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          //     font-weight: 400;
          //     color: #141516;
          //     //   line-height: 19px;
          //     vertical-align: super;
          //   }
          // }
        }
      }
      .h_right {
        width: 720px;
        height: 280px;
        padding: 10px 20px;
        // display: flex;
        // justify-content: flex-start;
        // flex-direction: column;
        .r_title {
          max-width: 720px;
          //   height: 29px;
          font-size: 22px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          //   line-height: 26px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 10px;
        }
        .r_user {
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }

        p {
          // width: 80px;
          // height: 21px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #5d6066;
          // line-height: 19px;
          margin-bottom: 12px;
        }
        .el-button {
          width: 160px;
        }
      }
    }
    main {
      padding: 42px 20px;

      background-color: #fff;
      margin-top: 25px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .writings {
        margin: 0px 12px 17px;
      }
    }
  }
}
</style>