<template>
  <div class="writings">
    <div class="writings_center">
      <!-- <h1>详情</h1> -->
      <header :style="cc">
        <span class="soa">{{listdata.id}}</span>
        <i v-if="listdata.type=='公告文档'" class="one"></i>
        <i v-if="listdata.type=='资料收集'" class="two"></i>
        <i v-if="listdata.type=='投票'" class="three"></i>
        <i v-if="listdata.type=='调查问卷'" class="four"></i>
        <span class="sob" @click="gotopath">{{listdata.type}}</span>
      </header>
      <main>
        <p>{{listdata.content}}</p>
      </main>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    listdata: {
      type: Object,
      default: null,
    },
    nowindex: {
      type: Number,
      default: 1,
    },
    listid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    gotopath() {
      this.$router.push({
        path: "/showactive",
        query: {
          title: this.listdata.title,
          ids: this.listdata.ids,
          index: this.nowindex,
          id: this.listid,
        },
      });
    },
  },
  computed: {
    cc() {
      console.log(this.listdata.type, "类型");
      if (this.listdata.type == "公告文档") {
        return `background: linear-gradient(90deg, #56C0CC 0%, #41A4D7 100%);`;
      } else if (this.listdata.type == "资料收集") {
        return `background: linear-gradient(90deg, #F3AE43 0%, #EFBD5F 100%);`;
      } else if (this.listdata.type == "投票") {
        return `background: linear-gradient(90deg, #7476E1 0%, #7879F5 86%, #7979F8 100%);`;
      } else if (this.listdata.type == "调查问卷") {
        return `background: linear-gradient(90deg, #FF7777 0%, #FF8E90 100%);`;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.writings {
  .writings_center {
    width: 265px;
    header {
      height: 24px;
      padding: 10px 20px;
      .soa {
        display: inline-block;
        border-radius: 50%;
        border: 1px solid #fff;
        text-align: center;
        // width: 18px;
        // height: 18px;
        // line-height: 18px;
        width: 16px;
        height: 16px;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 14px;
      }
      i {
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
      }
      .one {
        width: 24px;
        height: 24px;
        background: url("../assets/active/1.png") no-repeat;
      }
      .two {
        width: 22px;
        height: 21px;

        background: url("../assets/active/2.png") no-repeat;
      }
      .three {
        width: 21px;
        height: 21px;
        background: url("../assets/active/3.png") no-repeat;
      }
      .four {
        width: 21px;
        height: 21px;
        background: url("../assets/active/4.png") no-repeat;
      }
      .sob {
        display: inline-block;
        margin-left: 4px;
        // width: 64px;
        // height: 21px;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        // line-height: 19px;
        vertical-align: middle;
        cursor: pointer;
      }
    }
    main {
      height: 137px;
      border: 1px solid #ebebeb;
      overflow: hidden;
      padding: 18px 16px 0;
      p {
        // border: 1px solid #ebebeb;
        // width: 168px;
        // height: 18px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #141516;
        // line-height: 16px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 6;
      }
    }
  }
}
</style>